import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="date-picker"
export default class extends Controller {
  connect() {
    this.updateCalendars();
  }

  updateCalendars() {
    const calendarWrappers = Array.from(document.querySelectorAll('.calendar-wrapper'));
    calendarWrappers.forEach(wrapper => {
      const calendar = wrapper.querySelector('.calendar');
      const schoolDates = JSON.parse(wrapper.dataset.schoolDates);
      const dateType = wrapper.dataset.dateType;
      this.instantiateCalendar(dateType, calendar, schoolDates);
    });
  }

  updateCalendar(event) {
    const calendarWrapper = event.target.closest('.calendar-wrapper');
    const calendar = calendarWrapper.querySelector('.calendar');
    const schoolDates = JSON.parse(calendarWrapper.dataset.schoolDates);
    const dateType = event.target.value;
    calendar.value = '';
    this.instantiateCalendar(dateType, calendar, schoolDates);
  }

  instantiateCalendar(dateType, calendar, schoolDates) {
    if(dateType === 'range') {
      const range = {
        from: schoolDates[0],
        to: schoolDates[schoolDates.length - 1]
      };
      flatpickr(calendar, {
        mode: 'range',
        dateFormat: "Y-m-d",
        enable: [range]
      });
    } else {
      flatpickr(calendar, {
        mode: 'multiple',
        dateFormat: "Y-m-d",
        enable: schoolDates
      });
    }
  }
}
